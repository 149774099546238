@import "../common.scss";

footer {
  width: 1920px;
  height: 585px;
  margin: 0 auto;
  background-image: url(../assets/background-footer.svg);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .first-row {
    width: 1314px;
    height: 334px;
    margin-left: 345px;
    display: flex;
    .logo {
      width: 384px;
      height: 212px;
    }
    ul {
      height: 164px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 2px solid white;
      list-style-type: none;
      margin-left: 52px;
      margin-top: 52px;
      li {
        a {
          color: white;
          font-size: 30px;
          white-space: nowrap;
          font-family: 'Avenir';
          text-decoration: none;
        }
      }
    }
    .sm-icons {
      width: 318px;
      height: 125px;
      display: flex;
      margin-top: 36px;
      margin-left: 296px;
      position: relative;
      align-items: center;
      justify-content: space-between;
      svg, span {
        cursor: pointer;
      }
      span {
        right: 25px;
        bottom: -61px;
        font-size: 42px;
        position: absolute;
      }
    }
  }
  .second-row {
    box-sizing: border-box;
    padding-left: 50px;
    padding-top: 14px;
    height: 78px;
    border-top: 2px solid white;
    color: white;
    font-family: 'Avenir';
    font-size: 28px;
    font-weight: 300;
  }
}

.footer__team-page,
.footer__roadmap-page,
.footer__our-story-page {
  height: 364px;
  background: linear-gradient(#FBF1FF, white);
  .first-row {
    color: #7D47A0;
    ul {
      border-left: 2px solid #7D47A0;
      a {color: #7D47A0;}
    }
    svg {
      circle,
      rect,
      path {
        fill: #7D47A0;
        stroke: none;
      }
      #NEUTRE_G,
      #Forme_13,
      #Forme_14 {
        fill: white;
      }
      #NEUTRE_G1_copie {
        fill: white;
      }
    }
  }
  .second-row {
    color: #7D47A0;
    border-top-color: #7D47A0;
  }
}

.footer__roadmap-page {
  background: linear-gradient(#FEFDFF, white);
}

.footer__team-page {
  background: linear-gradient(#F5DFFF, #FAEEFF);
}