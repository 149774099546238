@import "../common.scss";

header {
  width: 1920px;
  margin: 0 auto;
  background-color: #4CB3C4;
  &.header__roadmap-page {
    background: transparent linear-gradient(341deg, #3F4385 0%, #51AABC 100%) 0% 0% no-repeat padding-box;
  }
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  margin: auto;
  padding: 8px 0 0 0;
  width: 1354px;

  a {
    color: white;
    text-decoration: none;
  }

  .whitpaper {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 251px;
    padding-left: 22px;
    svg {
      width: 26.51px;
      height: 34.8px;
    }
  }

  .bdouin-link {
    width: 353px;
  }

  .logo {
    width: 280px;
    svg {
      width: 156.54px;
      height: 82.98px;
    }
  }

  .team-link {
    width: 221px;
  }

  .roadmap-link {
    width: 178px;
  }

}

/* Bandeau image */
.bandeau-img {
  width: 100%;
  height: auto;
}