$xdReferenceWith: 1920;

$blue: #0697DB;
$black: #000000;
$greenDark: #1A5560;
$greenLight: #51AABC;

.sm-wrap {
  margin: auto;
  width: 1362px;
}

.one-cell {
  width: 97px;
  height: 94px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93px;
  height: 93px;
}

.full-border {
  border: 2px solid white;
}

.right-boder {
  border-right: 2px solid white;
}
