@import "../common.scss";

.our-story-page {
  width: 1920px;
  height: 3170px;
  margin-top: -6px;
  background: linear-gradient(#D2E0E2 25%, #E8D9F5, #F3D6FF, white 95%);

  .our-story-item__title-date__date {
    width: 133px;
    height: 59px;
    color: white;
    display: flex;
    font-size: 34px;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    font-family: 'Ayuthaya';
    background-color: #88A0A3;
    border: 2px solid white;
  }

  .our-story-item {
    display: flex;
    position: relative;
    hr {
      top: 106px;
      left: 140px;
      width: 90px;
      height: 2px;
      border: none;
      position: absolute;
      background-color: white;
    }
    &__title-date {
      width: 175px;
      height: 254px;
      display: flex;
      align-items: center;
      padding-right: 30px;
      box-sizing: border-box;
      flex-direction: column;
      &__title {
        display: flex;
        height: 81px;
        color: black;
        font-size: 21px;
        font-weight: 900;
        line-height: 1;
        margin-bottom: 3px;
        text-align: center;
        font-family: 'Avenir';
        align-items: flex-end;
        justify-content: center;
        text-transform: uppercase;
      }
    }
    &__image-desc {
      width: 964px;
      height: 203px;
      display: flex;
      margin-top: 17px;
      margin-left: 55px;
      padding: 20px 32px;
      padding-right: 17px;
      align-items: center;
      border-radius: 21px;
      box-sizing: border-box;
      background-color: white;
      box-shadow: 0px 3px 6px rgb(106 150 159 / 51%);
      img {
        width: 151px;
        height: 162px;
      }
      p {
        line-height: 1.5;
        color: black;
        font-size: 20px;
        margin-left: 20px;
        font-family: 'Ayuthaya';
      }
    }
  }
}