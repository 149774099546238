@import "../common.scss";

.our-story-page {
  width: 1920px;
  height: 3170px;
  margin-top: -6px;
  padding-left: 6px;
  background: linear-gradient(#D2E0E2 25%, #E8D9F5, #F3D6FF, #FBF1FF 100%);
  display: flex;
  justify-content: center;
  position: relative;
  .our-story-wrapper {
    margin-top: 90px;
    .vertical-line {
      top: 0;
      width: 0;
      height: 1452px;
      position: absolute;
      margin-left: 175px;
      box-sizing: border-box;
      border-right: 2px solid white;
      &.long-line {
        height: 156px;
      }
    }
    .horizontal-line {
      width: 968px;
      margin-top: 90px;
      margin-left: 175px;
      border-top: 1px solid white ;
      border-bottom: 1px solid white ;
    }
    .our-story-element {
      width: 1308px;
    }
    .story-2015 {
      position: relative;
      hr {
        left: auto;
        width: 2px;
        height: 88px;
        top: 100px;
      }
      .our-story-item {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -78px;
        .our-story-item__title-date {
          padding: 0;
          height: 193px;
          justify-content: flex-start;
          border: none;
          .our-story-item__title-date__title {
            height: auto;
          }
          .our-story-item__title-date__date {
            background-color: black;
          }
        }
        .our-story-item__image-desc {
          width: 572px;
          height: 369px;
          padding: 0;
          margin: 0;
          padding-left: 27px;
          flex-direction: row-reverse;
          p {margin: 0}
          img {
            width: 263px;
            height: 263px;
          }
        }
      }
      .vertical-line {
        top: 76px;
        left: 968px;
        height: 1390px;
      }
    }
    .our-story-element-right {
      width: 1321px;
      margin-top: 60px;
      .our-story-item {
        flex-direction: row-reverse;
        hr { right: 140px; left: auto;}
        .our-story-item__title-date {
          padding-right: 0;
          padding-left: 30px;
          .our-story-item__title-date__date {
            background-color: #8079B9;
          }
        }
        .our-story-item__image-desc {
          margin-left: 0;
          margin-right: 55px;
          padding-left: 17px;
          padding-right: 27px;
          flex-direction: row-reverse;
          p {
            margin-left: 0;
            text-align: right;
            margin-right: 20px;
          }
        }
      }
    }
    .next-for-2022 {
      width: 326px;
      display: flex;    
      margin-top: 80px;
      margin-left: 983px;
      flex-direction: column;
      align-items: center;
      &-title {
        font-size: 34px;
        color: #7D47A0;
        font-family: 'Ayuthaya';
        margin-top: 10px;
      }
      .our-story-item__title-date__date {
        background-color: #7D47A0;
      }
    }
  }
}
