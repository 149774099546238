@import "../common.scss";

.reason-header {
  margin: 0;
  display: flex;
  align-items: center;
  height: 94px;
  margin-left: 9px;
  border: 2px solid white;
}

.reason-header .reason-number {
  font-family: 'Fredoka One';
  font-size: 63px;
}

.reason-header .reason-title {
  font-family: 'Ayuthaya';
  font-size: 43px;
  margin-left: 34px;
}

/* First section (why nftpx*/
section {
  margin: auto;
  width: 100%;
}
.why-nft {
  background-size: contain;
  background-image: url(../assets/background.jpg);
  height: 4309px;
  margin-top: -10px;
}

.left-border {
  border-left: 2px solid white;
}
.separator {
  width: 972px;
  height: 96px;
  margin-left: 104px;
}

.first-reason .reason-header {
  width: 1064px;
}

.hidden-text {
  display: flex;
  position: relative;
  height: 97px;
  width: 1258px;
  margin-left: 11px;
}

.hidden-text .close-btn {
  color: black;
  transform: scale(0);
  transition: transform 500ms;
  font-family: 'Fredoka One';
  font-size: 63px;
}

.hidden-text .description {
  width: 0;
  color: black;
  overflow: hidden;
  font-family: 'Ayuthaya';
  font-size: 18px;
  box-sizing: border-box;
  width: 1068px;
  > div {
    height: 97px;
    width: 1068px;
    padding-top: 10px;
    padding-left: 39px;
    box-sizing: border-box;
    transform: translate(100%, 0);
    transition: transform 500ms;
    background-color: white;
  }
}

.unveil.hidden-text .description > div {
  transform: translate(0, 0);
}

.unveil-btn {
  top: 0;
  right: 0;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-items: center;
  justify-content: center;
}

.unveil-btn svg.unveil-icon {
  height: 65px;
  width: 65px;
  transition: transform 500ms;
}

.unveil .unveil-btn svg.unveil-icon {
  transform: rotate(-180deg);
}

.reason-description {
  color: black;
  line-height: 1.45;
  box-sizing: border-box;
  font-family: 'Ayuthaya';
  background-color: white;
  font-size: 20px;
  padding: 49px 39px;
  width: 968px;
  height: 385px;
  margin-left: 104px;
}

.unveil.hidden-text .close-btn {
  cursor: pointer;
  transform: scale(1);
}

.unveil-cursor {
  position: absolute;
  width: 42px;
  height: 42px;
  right: -17px;
  bottom: -17px;
}

.second-reason .sm-wrap {
  box-sizing: border-box;
  padding-left: 96px;
}

.second-reason .reason-header {
  width: 582px;
}

.second-reason .reason-header .reason-title {
  font-size: 43px;
}

.second-reason .reason-description {
  margin-left: 0;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 150px;
  width: 1645px;
  height: 387px;
}

.second-reason .reason-description img {
  margin-right: 24px;
  width: 341px;
  height: 283px;
}

.second-reason .reason-description .reason-description-text {
  width: 1090px;
  height: auto;
}

.reason-description-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.third-reason  .reason-header {
  width: 1164px;
}

.third-reason  .reason-header .reason-title {
  font-size: 46px;
}

.third-reason  .reason-description {
  padding: 0;
  height: 387px;
  width: 1632px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.third-reason  .reason-description span {
  margin-right: 60px;
  width: 1047px;
}

.third-reason  .reason-description img {
  height: 100%;
  width: auto;
}

.our-believes {
  margin: auto;
  font-family: 'Ayuthaya';
  height: 714px;
  width: 1100px;
  font-size: 35px;
  margin-top: 197px;
}

.our-believes h2 {
  font-size: 62px;
}

.our-believes p {
  line-height: 1.2;
  margin-block-start: 0.75em;
  margin-block-end: 0.75em;
}

.our-believes p span.full-border {
  border: 1px #FFFFFF solid;
  padding-top: 0;
  padding-bottom: 0;
}

.our-believes p .highlighted {
  color: white;
}

.our-believes p .highlighted span {
  z-index: 1;
  color: #3EA2AC;
  position: absolute;
  margin-left: 18px;
}

.our-believes p .highlighted svg {
  width: auto;
  position: absolute;
  height: 50px;
}

.our-believes p span.underligned {
  border-bottom: 4px solid white;
}

.try-your-self {
  margin-top: 184px;
  position: relative;
  img {
    width: 1920px;
    height: 1380px;
  }
  .user-text {
    top: 296px;
    left: 284px;
    width: 645px;
    font-size: 39px;
    text-align: center;
    position: absolute;
    font-family: "Ayuthaya";
  }
  > div {
    position: absolute;
    top: 143px;
    left: 940px;
    width: 660px;
    input {
      width: 660px;
      height: 106px;
      font-size: 29px;
      padding-left: 30px;
      border-radius: 10px;
      font-family: 'Avenir';
      box-sizing: border-box;
      border: none;
      margin-left: 47px;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    }
    .instruction {
      color: black;
      font-weight: 900;
      font-size: 32px;
      font-family: 'Avenir';
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      svg {
        width: 32px;
        height: 32px;
        margin-top: 20px;
        margin-right: 15px;
      }
    }
  }
}

.about-us {
  height: 3440px;
  background-color: black;
  padding-top: 10px;
  .who-we-are {
    display: flex;
    align-items: center;
    flex-direction: column;
    .logo-2 {
      display: flex;
      color: #9CE5FC;
      font-size: 33px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-family: 'Ayuthaya';
      svg {
        width: 598px;
        height: 240px;
        margin-bottom: 15px;
      }
    }
    a.button,
    button {
      width: 450px;
      text-transform: uppercase;
    }
  }
}

a.button {
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

a.button,
button {
  height: 49px;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-weight: 900;
  border-radius: 42px;
  font-family: 'Avenir';
  background-color: #78DAFC;
}

.our-story {
  width: 1353px;
  height: 856px;
  display: flex;
  margin-top: 182px;
  justify-content: space-between;
  .first-column,
  .second-column,
  .third-column {
    width: 396px;
    font-size: 20px;
    font-family: 'Ayuthaya';
    h2 {
      text-align: center;
    }
    > div {
      width: auto;
      height: 318px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.what-we-did {
  margin: auto;
  width: 1010px;
  display: flex;
  margin-top: 276px;
  .first-column {
    width: 510px;
    p {
      font-size: 31px;
      font-family: 'Ayuthaya';
    }
    .slider {
      width: 448px;
      height: 882px;
      position: relative;
      overflow: hidden;
      border-radius: 36px;
      svg {
        position: absolute;
      }
      > div {    
        top: 50px;
        left: 20px;
        width: 2055px;
        position: absolute;
        transform: translate(0, 0);
        transition: transform 500ms;
        &.example1 {
          transform: translate(-411px, 0);
        }
        &.example2 {
          transform: translate(-822px, 0);
        }
        &.example3 {
          transform: translate(-1233px, 0);
        }
        &.example4 {
          transform: translate(-1644px, 0);
        }
        img {
          width: 411px;
          height: 813px;
        }
      }
    }
    .slider-nav {
      width: 448px;
      display: flex;
      cursor: pointer;
      margin-top: 10px;
      justify-content: center;
      &-left {
        margin-right: 15px;
        transform: rotate(180deg);
      }
      &-right {
        margin-left: 15px;
      }
    }
  }
  .second-column {
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 500px;
    img {
      width: 296px;
      height: 296px;
    }
    p {
      color: #4CBCE3;
      font-family: 'Ayuthaya';
      font-size: 35px;
      text-align: center;
      text-transform: uppercase;
      margin: 20px 0;
      &.app-story {
        color: #E4E4E4;
        font-size: 36px;
        text-transform: none;
      }
      &.nb-downloads {
        margin: 0;
        font-size: 54px;
        color: #778FF4;
      }
      &.evaluation {
        display: flex;
        align-items: center;
        color: #778FF4;
        font-size: 67px;
        margin: 0;
        svg {
          margin-left: 20px;
        }
      }
      &.nb-ratings {
        margin: 0;
        width: 100%;
        color: #778FF4;
        text-align: right;
        font-size: 30px;
      }
    }
    .icon-playstore {margin-right: 28px;}
  }
}

.our-stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 148px;
  p {
    color: white;
    margin: 0;
    font-family: 'Fredoka One';
    &.nb-elements {
      font-size: 59px;
    }
    &.nb-creations {
      line-height: 0.9;
      font-size: 294px;
    }
    &.nb-creations-desc {
      line-height: 0.9;
      font-size: 86px;
      text-transform: uppercase;
    }
  }
  a.button,
  button {
    width: 457px;
    margin-top: 31px;
    background-color: #778FF4;
    text-transform: uppercase;
  }
}

.next-step {
  display: flex;
  position: relative;
  align-items: flex-end;
  background-color: black;
  height: 2132px;
  margin: -2px;
  svg {
    margin-bottom: -10px;
  }
  div {
    top: 650px;
    left: 928px;
    position: absolute;
    color: black;
    font-size: 38px;
    font-family: 'Ayuthaya';
    p {
      margin-top: 20px;
      line-height: 1.3;
    }
  }
}