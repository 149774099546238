@import "../common.scss";

.team-page {
  margin-top: -8px;
  background: linear-gradient(#C6C6C6, #E8D9F5, #F5DFFF);
  section {
    width: 100%;
    display: flex;
    padding-top: 25px;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    &:last-child {
      padding-bottom: 100px;
    }
    .team-role-title {
      margin: 0;
      width: 100%;
      display: flex;
      align-items: center;
      hr {
        flex: 1;
        margin: 0;
        height: 1px;
        border: none;
        background-color: white;
      }
      span {
        font-size: 40px;
        margin-left: 25px;
        margin-right: 25px;
        font-family: 'Ayuthaya';
      }
    }
    .team-listing {
      margin-top: 15px;
      max-width: 1259px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .person-card
      {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 35px;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &.no-right-margin {
          margin-right: 0;
        }
      }
    }
  }
}