@import "../common.scss";

.person-card {
  width: 389px;
  height: 560px;
  border-radius: 21px;
  background-color: white;
  box-shadow: 0px 3px 6px rgb(147 147 147 / 51%);
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #000000;
  text-align: center;
  font-family: 'Ayuthaya';
  font-size: 20px;
  &.disabled {
    opacity: 0.5;
  }
  .person-card-empty {
    background-color: #C7C7C8;
  }

  img,
  .person-card-empty {
    width: 342px;
    height: 226px;
    margin-top: 19px;
    border-radius: 14px;
  }
  h3 {
    margin: 15px auto;
    margin-top: 20px;
    font-size: 20px;
    span {
      letter-spacing: -5px;
    }
    text-transform: uppercase;
  }
  p {
    width: 352px;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.3;
    font-family: 'Avenir Next Condensed';
  }
  .link-w {
    margin-left: 20px;
  }
}