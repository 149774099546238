@import "../common.scss";

iframe {
  width: 100%;
  height: calc(var(--screenHeight)/var(--scale));
}

.header__userscenes-page,
.footer__userscenes-page {
  display: none;
}