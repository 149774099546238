@import "../common.scss";

img.nft-example {
  width: 1670px;
  height: 937px;
}

.roadmap-wrapper {
  overflow: hidden;
  position: relative;
  .schemas {
    top: 42px;
    left: 259px;
    position: absolute;
  }
  .rm-intro {
    font-family: 'Ayuthaya';
    h2 {
      font-size: 55px;
      text-transform: uppercase;
    }
    p {
      font-size: 22px;
    }
    span {
      font-size: 41px;
    }
    .rm-intro-intro-1 {
      top: 30px;
      z-index: 1;
      left: 313px;
      position: absolute;
      h2 {
        margin-left: 150px;
      }
      p {
        line-height: 1.3;
        width: 390px;
        height: 582px;
        color: #29377A;
        margin-top: 73px;
        margin-left: 53px;
        margin-bottom: 42px;
      }
      span {
        margin-left: 565px;
      }
      .rm-intro-intro-1-example {
        right: 20px;
        bottom: 135px;
        cursor: pointer;
        position: absolute;
      }
    }

    .rm-intro-intro-2 {
      top: 1000px;
      z-index: 1;
      left: 500px;
      position: absolute;

      h2 {
        font-size: 52px;
        margin-left: 100px;
      }
      p {
        line-height: 1.3;
        width: 408px;
        height: 642px;
        margin-top: 50px;
        margin-left: 840px;
        margin-bottom: 10px;
      }
      span {
        margin-left: 0;
      }
    }
  }
  .rm-roadmap {
    height: 2537px;
    position: relative;
    svg {
      g[id$="_INFOS"] {
        opacity: 0;
        display: none;
        transition: opacity 500ms;
        // transform: scale(0);
        &.showInfo {
          display: block;
          opacity: 1;
        }
      }
      g[id^="FRAME_"] {
        position:relative;
        cursor: pointer;
      }
    }
    .rm-roadmap-bkp {
      margin-top: -140px;
    }
    .rm-roadmap-path {
      top: -167px;
      left: 240px;
      position: absolute;
    }
  }

}