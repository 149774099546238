@import "../font.scss";

body {
  margin: auto;
  background-color: white;
  overflow: hidden;
  &.blockScrolling {
    overflow: hidden;
  }
}

#root {
  overflow: hidden;
}

#main {
  margin: auto;
  width: 1920px;
  font-family: 'Avenir';
  color: white;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(var(--screenHeight)/var(--scale));
  padding-right: 0 !important;
  background-color: white;
  transform: scale(var(--scale));
  transform-origin: 0 0;
}

.not-authenticated-container {
  margin-top: -8px;
  overflow: hidden;
  position: relative;
  max-height: calc(var(--screenHeight)/var(--scale) - 194px);
  .not-authenticated-img {
    width: 1920px;
    height: 1320px;
  }
  .authentication-form {
    top: 96px;
    left: 676px;
    width: 581px;
    height: 98px;
    position: absolute;
    display: flex;
    input {
      width: 485px;
      height: 98px;
      border: 2px solid white;
      display: block;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 0.7);
      font-size: 36px;
      font-family: 'Avenir Next Condensed';
      text-align: center;
    }
    &.error {
      background-color: #DE8579;
    }
    &.success {
      background-color: #51AABC;
    }
    button {
      width: 98px;
      height: 98px;
      border-radius: 0;
      box-sizing: border-box;
      border: 2px solid white;
      border-left: 0;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.ReactModal__Content {
  .close-btn {
    top: 40px;
    right: 40px;
    cursor: pointer;
    position: absolute;
  }
  .world-map {
    width: auto;
    height: 916px;
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  .ReactModal__Content {
    padding: 0 !important;
    transform-origin: 0 0;
    border: none !important;
    outline: none !important;
    overflow: auto !important;
    border-radius: 0 !important;
    background: transparent !important;
    transform: scale(var(--scale)) translate(-50%, -50%) !important;
  }
}